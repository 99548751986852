.rmdb-actor {
	height: auto;
	min-height: 30px;
	font-weight: bold;
	text-align: center;
	color: $bgcolor; }

.rmdb-actor img {
	width: 100%;
	height: 215px;
	object-fit: contain;
	margin: 0 auto; }

.rmdb-actor-name {
	font-size: 22px;
	margin-top: 10px; }

.rmdb-actor-character {
	font-size: 18px;
	display: block; }
