.rmdb-movieinfobar {
	display: flex;
	align-items: center;
	width: 100%;
	min-height: 100px;
	height: auto;
	background: $bgcolor;
	padding: 20px;
	font-size: 20px; }

.rmdb-movieinfobar-content {
	padding: 0;
	width: 100%;
	margin: 0 auto;
	color: #fff;
	display: flex;
	justify-content: space-evenly;
	align-items: center; }

.rmdb-movieinfobar-content-col {
	display: flex;
	align-items: center; }

.rmdb-movieinfobar-info {
	padding-left: 15px; }
