.rmdb-moviethumb img {
	width: 100%;
	height: auto;
	transition: all 0.3s;
	object-fit: cover; }

.clickable {
	cursor: pointer; }

.clickable:hover {
	opacity: 0.8; }
