* {
	margin: 0;
	padding: 0;
	font-family: 'Abel', sans-serif;
	box-sizing: border-box; }

body {
 }	//overflow: overlay

// Base
@import "_base/base";


// Pages
@import "_pages/Home";
@import "_pages/Movie";

// Components
@import "_components/Header";
@import "_components/MovieInfo";
@import "_components/MovieInfoBar";
@import "_components/MovieThumb";
@import "_components/Menu";
@import "_components/HeroImage";
@import "_components/SearchBar";
@import "_components/Actor";
@import "_components/FourColGrid";
@import "_components/Navigation";
@import "_components/Spinner";


@import "_base/media";
