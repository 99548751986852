.rmdb-searchbar-content {
	width: 100%;
	background: #353535;
	height: 40px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: #fff;
	padding: 0 15px; }

.rmdb-searchbar-input {
	font-size: 24px;
	border: 0;
	background: transparent;
	color: #fff;
	width: 90%;
	height: 25px; }

.rmdb-fa-search {
	margin-right: 20px;
	color: #fff; }

.rmdb-searchbar-input:focus {
	outline: none; }
