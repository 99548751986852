.rmdb-menu {
	width: 100%;
	background: $bgcolor;
	padding-bottom: 10px; }

.rmdb-menu-content {
	@include center-between;
	padding: 0; }

.rmdb-menu-content-links {
	@include center-between;
	width: 180px; }

.rmdb-menu-link {
	border: 0;
	background-color: transparent;
	color: #fff;
	font-size: 25px;
	cursor: pointer; }

.rmdb-menu-link:hover,
.rmdb-menu-link.active {
	color: #ccc;
	border-bottom: 3px solid #ccc; }

.rmdb-menu-link:focus {
	outline: none; }

.rmdb-menu-content-search {
	width: 500px; }
