@keyframes animateGrid {
	from {
		opacity: 0; }
	to {
		opacity: 1; } }

@keyframes animateHeroimage {
	0% {
		opacity: 0; }
	100% {
		opacity: 1; } }

@keyframes animateMovieinfo {
	from {
		opacity: 0; }
	to {
		opacity: 1; } }
