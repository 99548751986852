.rmdb-navigation {
	display: flex;
	align-items: center;
	width: 100%;
	height: 40px;
	background: #353535; }

.rmdb-navigation-content p,
.rmdb-navigation-content a {
	color: #fff;
	text-decoration: none;
	margin: 0px; }

.rmdb-navigation-content {
	margin: 0 auto;
	padding: 0;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center; }

.rmdb-navigation-content p {
	font-size: 22px;
	padding-right: 10px;
	text-decoration: none; }

.rmdb-navigation-button {
	border: 0;
	color: #fff;
	background-color: transparent;
	font-size: 22px; }

.rmdb-navigation-button:focus {
	outline: none; }
