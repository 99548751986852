@media screen and (max-width: 1300px) {
	.rmdb-movieinfo-thumb {
		width: 60%; } }


@media screen and (max-width: 1200px) {
	.rmdb-grid-content {
		grid-template-columns: repeat(5, 1fr); } }


@media screen and (max-width: 1150px) {
	.rmdb-movieinfo-thumb {
		width: 70%; }

	.rmdb-movieinfo-thumb {
		width: 100%; } }


@media screen and (max-width: 1100px) {
	.rmdb-grid-content {
		grid-template-columns: repeat(3, 1fr); }

	.rmdb-movieinfo-text h1 {
		font-size: 32px !important; }

	.rmdb-movieinfo-thumb {
		display: none; } }


@media screen and (max-width: 800px) {
	.rmdb-movieinfobar-content-col {
		flex-direction: column; }

	.rmdb-movieinfobar-info {
		padding-top: 5px;
		padding-left: 0; } }


@media screen and (max-width: 770px) {
	.rmdb-heroimage {
		min-height: 350px; }

	.rmdb-heroimage-text {
		max-width: 100%; }

	.rmdb-heroimage-text h1 {
		font-size: 38px; }

	.rmdb-heroimage-text p {
		font-size: 16px;
		line-height: 20px; }

	.rmdb-grid-content {
		grid-template-columns: repeat(2, 1fr); }

	.rmdb-grid h1 {
		font-size: 22px; }

	.rmdb-navigation-content p,
	.rmdb-navigation-button {
		font-size: 16px; } }


@media screen and (max-width: 740px) {
	.rmdb-menu {
		padding-bottom: 0px; }

	.rmdb-menu-content {
		flex-direction: column; }

	.rmdb-menu-content-search {
		width: 100%;
		margin-top: 15px; } }


@media screen and (max-width: 720px) {
	.rmdb-searchbar-input {
		font-size: 22px; } }


@media screen and (max-width: 700px) {
	.rmdb-movieinfo {
		height: auto; }

	.rmdb-movieinfo-thumb {
		width: 100% !important; }

	.fa-film {
		display: none; }

	.rmdb-movieinfo-text h1 {
		font-size: 32px; }

	.rmdb-movieinfo-text {
		padding: 20px; }

	.rmdb-movieinfo-text h3 {
		margin-top: 20px; }

	.rmdb-movieinfo-content {
		flex-direction: column; } }


@media screen and (max-width: 500px) {
	.rmdb-tmdb-logo {
		width: 80px; }

	.rmdb-logo {
		width: 150px; } }


@media screen and (max-width: 470px) {
	.rmdb-grid-content {
		grid-template-columns: repeat(1, 1fr); } }


@media screen and (max-width: 425px) {
	.rmdb-movieinfobar {
		font-size: 14px; } }
