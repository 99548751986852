.rmdb-movieinfo {
	background-size: cover !important;
	background-position: center !important;
	width: 100%;
	padding: 30px 20px;
	animation: animateMovieinfo 1s; }


.rmdb-movieinfo-content {
	padding: 0;
	min-height: 360px;
	margin: 0 auto;
	background: rgba(0, 0, 0, 0.7);
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center; }

.rmdb-movieinfo-thumb {
	width: 100%;
	max-width: 300px; }

.rmdb-movieinfo-text {
	padding: 40px;
	color: #fff;
	overflow: hidden; }

.rmdb-movieinfo-text h1 {
	font-size: 48px;
	margin: 0; }

.rmdb-movieinfo-text h3 {
	font-size: 16px;
	margin-top: 30px; }

.rmdb-movieinfo-text p {
	font-size: 18px;
	line-height: 26px; }

.rmdb-rating {
	width: 190px;
	height: 20px;
	margin-top: 20px;
	position: relative; }

.rmdb-score {
	position: absolute;
	margin: 0;
	right: 0px;
	top: -3px; }

.rmdb-director {
	margin: 0; }

.fa-film {
	position: absolute;
	bottom: 40px;
	right: 40px;
	color: #fff; }

meter::-webkit-meter-bar {
	background: #fff;
	width: 150px; }
meter::-webkit-meter-optimum-value {
	background: linear-gradient(to bottom, #16d47b) !important; }

meter::-webkit-meter-suboptimum-value {
	background: linear-gradient(to bottom, #fbb450) !important; }

meter::-webkit-meter-even-less-good-value {
	background: linear-gradient(to bottom, #ee5f5b) !important; }
