.rmdb-header {
	width: 100%;
	background: $bgcolor; }

.rmdb-header-content {
	@include center-between;
	padding: 20px 0px; }

.rmdb-logo {
	width: 200px; }

.rmdb-tmdb-logo {
	width: 100px; }

.rmdb-tmdb-offline {
	background-color: tomato;
	color: #FFF;
	text-align: center;
	font-weight: bold;
	font-size: 17px; }
