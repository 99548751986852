.rmdb-heroimage {
	background-size: 100%, cover !important;
	background-position: center, center !important;
	width: 100%;
	height: 500px;
	position: relative;
	animation: animateHeroimage 1s; }

.rmdb-heroimage-content {
	@include center-between;
	padding: 20px 0;
	margin: 0 auto; }

.rmdb-heroimage-text {
	z-index: 100;
	max-width: 700px;
	position: absolute;
	bottom: 20px;
	margin-right: 20px;
	min-height: 100px;
	background: rgba(0, 0, 0, 0.0);
	color: #fff; }

.rmdb-heroimage-text h1 {
	font-size: 48px; }

.rmdb-heroimage-text p {
	font-size: 22px;
	line-height: 24px; }
