.rmdb-grid h1 {
	font-size: 36px;
	margin: 15px 0; }

.rmdb-grid-content {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-gap: 40px;
	position: relative; }

.rmdb-grid-element {
	animation: animateGrid 0.5s; }
